type Options = {
	title?: string;
	required?: boolean;
	min?: number;
	max?: number;
	regExp?: RegExp;
};

export function valid(
	value: string,
	{ title = 'Field', required = false, min, max, regExp }: Options
) {
	if (required && !value) return `${title} cannot be empty`;
	if (!required && !value) return ''; // if it's not required empty is a valid value

	if (min && value.length < min) return `${title} must be larger than ${min} characters`;
	if (max && value.length > max) return `${title} cannot exceed ${max} characters`;
	if (regExp && !regExp.test(value)) return `${title} is invalid`;
	return '';
}
