import type { AddMethodSuccess, Address, PaymentSession, UpdateSessionStatus } from '$lib/types';

import { PAYMENT_ADDRESS_ENDPOINT, PAYMENT_SESSION_ENDPOINT } from '$lib/constants';

import { get, req } from './http';

// GET /p/request auth:payment-session-token header
export async function getSession(token: string) {
	if (!token) throw new Error('invalid payment session: payment session token not found');

	// GET /p/session auth:payment-session-token header
	return await get<PaymentSession>(PAYMENT_SESSION_ENDPOINT, {
		headers: { 'payment-session-token': token },
	});
}

// POST /p/session auth:payment-session-token header
export async function updateSessionStatus(token: string, status: UpdateSessionStatus) {
	if (!token) throw new Error('invalid payment session: payment session token not found');

	return await get<AddMethodSuccess>(PAYMENT_SESSION_ENDPOINT, {
		method: 'POST',
		headers: { 'payment-session-token': token },
		body: JSON.stringify(status),
	});
}

type AddressAPI = Omit<Address, 'state'> & { state: string | null };

// POST /p/address auth:payment-session-token header
export async function upsertAddress(token: string, address: Address) {
	if (!token) throw new Error('invalid payment session: payment session token not found');

	// /p/address does NOT support state = '', it should be null instead of empty string
	const addressAPI: AddressAPI = { ...address, state: address?.state || null };

	await req(PAYMENT_ADDRESS_ENDPOINT, {
		method: 'POST',
		headers: { 'payment-session-token': token },
		body: JSON.stringify(addressAPI),
	});
}

// GET /p/address auth:payment-session-token header
export async function readAddress(token: string) {
	if (!token) throw new Error('invalid payment session: payment session token not found');

	const addressAPI = await get<AddressAPI>(PAYMENT_ADDRESS_ENDPOINT, {
		headers: { 'payment-session-token': token },
	});
	// /p/address does NOT support state = '', it should be null instead of empty string
	const address: Address = { ...addressAPI, state: addressAPI?.state || '' };
	return address;
}
