<script lang="ts">
	import ErrorMessage from './ErrorMessage.svelte';

	export let disabled = false;
	/** Id of the input. If there's also a label specified it will be linked to that id using the `for` html attribute. */
	export let id: string | undefined = undefined;
	/** Optional title to display above the tag */
	export let title = '';
	export let placeholder = '';
	/** Optional text to show as prefix inside the input */
	export let prefix = '';
	/** Optional text to show as suffix inside the input */
	export let suffix = '';
	export let invalid = false;
	export let value: string | undefined = '';
	export let error = '';

	export { className as class };
	let className = '';
</script>

<!-- container for whole input (title, input-container, error) -->
<div class="grid gap-2 self-start font-sans {className}">
	{#if title}<label for={id}>{title}</label>{/if}

	<!-- container with label and input simulating a single input -->
	<div
		class:disabled
		class:invalid={!!error || invalid}
		class:prefix
		class:suffix
		class="input-container"
	>
		{#if prefix}<span class="prefix">{prefix}</span>{/if}

		<input bind:value {id} {disabled} {placeholder} type="text" on:input />

		{#if suffix}<span class="prefix">{suffix}</span>{/if}
	</div>

	<ErrorMessage {error} />
</div>

<style lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	label {
		@apply block text-h200 font-normal text-zinc-600;
	}

	.prefix {
		@apply text-h200 font-normal text-zinc-900;
	}

	/* style input container to simulate input box */
	div.input-container {
		/* include icons and text on the same line without needing a container */
		@apply grid grid-flow-col items-center gap-2;
		/* h-[46.3px] => match height of stripe Payment element (which cannot be configured) */
		@apply h-[46.3px] w-full px-2;
		@apply rounded-lg border border-solid border-zinc-300 outline-none;
		@apply bg-white text-h200 font-normal text-zinc-500;
		@apply shadow-inner;
	}
	div.input-container.prefix {
		@apply grid-cols-[auto,1fr,auto];
	}
	div.input-container.suffix {
		@apply grid-cols-[1fr,auto];
	}
	div.input-container.prefix.suffix {
		@apply grid-cols-[auto,1fr,auto];
	}
	/* active input */
	div.input-container:focus-within {
		@apply text-zinc-900;
		@apply shadow-active-input;
	}
	/* inactive invalid input: show invalid input red outline (override when focused) */
	div.input-container.invalid {
		@apply border-invalid;
	}
	/* active invalid input: show invalid input shadow, hide red outline */
	div.input-container.invalid:focus-within {
		@apply border-transparent shadow-active-danger;
	}

	input {
		@apply w-full grow;
		@apply border-none outline-none;
		@apply text-zinc-900;
	}
	input:focus,
	input:placeholder-shown {
		@apply text-zinc-900;
		@apply outline-none;
	}
	.input-container.disabled,
	input:disabled {
		@apply bg-zinc-200;
	}
</style>
