import { type Address, EMPTY_ADDRESS } from '$lib/types';

import { COUNTRIES, COUNTRIES_WITH_STATE } from '$lib/constants';

import type { Err } from '$lib/utils/error';
import { valid } from '$lib/utils/validations';

export function validateAddress(address: Address): Err | null {
	const error = validateAddressFields(address, undefined, 'all');

	// filter fields with error
	const fields = Object.keys(error).reduce((acum, field) => {
		const key = field as keyof Address; // so that TypeScript won't complain
		if (error[key]) acum[key] = error[key];
		return acum;
	}, {} as Partial<Address>);

	// no error
	if (Object.keys(fields).length === 0) return null;

	return {
		message: 'Some fields have invalid values',
		isValid: false,
		fields,
	};
}

// WARNING: these validations should be kept in sync with the backend
export function validateAddressFields(
	address: Address,
	error?: Address,
	field: keyof Address | 'all' = 'all'
) {
	if (!error) error = { ...EMPTY_ADDRESS };

	// empty fields are not validated if empty
	// imitate payment element behavior
	if (field !== 'all' && address[field] === '') {
		error[field] = '';
		return error;
	}

	const { holder_name, line_1, line_2, city, zip, state, country } = address;

	if (field === 'all' || field === 'holder_name')
		error.holder_name = valid(holder_name, { title: "Cardholders's name", min: 5 });

	if (field === 'all' || field === 'line_1')
		error.line_1 = valid(line_1, { title: 'Address first line', required: true, min: 3, max: 40 });

	if (field === 'all' || field === 'line_2')
		error.line_2 = valid(line_2, { title: 'Address second line', min: 3, max: 40 });

	if (field === 'all' || field === 'city')
		error.city = valid(city, { title: 'City', required: true, min: 3, max: 35 });

	if (field === 'all' || field === 'zip') {
		error.zip = valid(zip, {
			title: 'Zip code',
			required: true,
			min: 2,
			max: 10,
			regExp: /^[\w -]{2,10}$/,
		});
	}

	if (field === 'all' || field === 'state') {
		error.state = valid(state, { title: 'State', required: false, min: 2, max: 35 });
		// validate state is specified for countries that require a state
		if (!error.state && !state && country && COUNTRIES_WITH_STATE.includes(country))
			error.state = `State can't be empty for this country`;
	}

	if (field === 'all' || field === 'country') {
		error.country = valid(country, { title: 'Country', required: true, min: 2, max: 2 });
		// validate that country exists
		if (!error.country && !COUNTRIES.includes(country)) error.country = 'Country is invalid';
	}

	return error;
}
