import { URL_API, DEV_MODE as dev } from '$lib/constants';

import { errFromException, errFromResponse } from '$lib/utils/error';

const defaults: RequestInit = {
	method: 'GET',
	mode: 'cors',
	credentials: 'include',
	headers: {
		'Content-Type': 'application/json',
	},
};

export async function req(url: string, options: RequestInit = {}) {
	let response: Response;

	try {
		response = await fetch(`${URL_API}${url}`, { ...defaults, ...options });
		if (dev) log(url, options, response);
	} catch (ex) {
		if (dev) console.log({ ex });
		throw errFromException(ex);
	}
	if (!response.ok) throw await errFromResponse(response); // try to parse feedback to build the error
	return response;
}

const log = (url: string, options: RequestInit = {}, response: Response) => {
	const label = [
		response.ok ? '✅' : '❌',
		response.status,
		(options.method || 'GET').substring(0, 4).padEnd(4),
		url,
	].join(' ');
	let request = options;
	// browser
	if (typeof window !== 'undefined') {
		// format body to easily show it in web inspector
		if (request.body) request = { ...request, body: JSON.parse(request.body.toString()) };
		console.log({ [label]: { request, response } });
	} else {
		console.log(label);
	}
};

export async function get<E>(url: string, options: RequestInit = {}) {
	const response = await req(url, options); // method: GET
	return (await response.json()) as E;
}
