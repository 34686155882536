// get the current columns od a responsive html grid
export function gridCols(el: Element, defaultCols = 4) {
	if (!window) return defaultCols;
	const template = window.getComputedStyle(el).gridTemplateColumns;
	return template.split(' ').length;
}

// sets the height of each HTMLElement to the maxHeight of the rest
// grouped by cols
export function setHeightToMax(htmlEls: HTMLElement[], cols?: number) {
	const els = htmlEls.filter((el) => !!el); // filter empty elements

	// clear previously calculated height
	for (const el of els) el.style.height = 'auto';

	cols = cols || els.length;
	for (let i = 0; i < els.length; i += cols) {
		const row = els.slice(i, i + cols);
		const max = Math.max(...row.map((h) => h.clientHeight));
		for (const el of row) el.style.height = `${max}px`;
	}
}

// svelte use:resize action
export function resize(node: HTMLElement, callback: (target: Element) => unknown) {
	const observer = new ResizeObserver(([entry]) => callback(entry.target));
	observer.observe(node);
	return { destroy: () => observer.unobserve(node) };
}
