<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let value = 1;
	export let minimum_units: number | undefined | null = 1;
	export let maximum_units: number | undefined | null = undefined;
	export let batch_size: number | undefined | null = undefined;
	export let error = false;

	const dispatch = createEventDispatcher<{ change: number }>();

	// normalize prop values with adequate defaults
	$: batch = batch_size || 1;
	$: min = minimum_units || 0;
	$: max = maximum_units === undefined || maximum_units === null ? null : maximum_units;

	// used to grey out the +/- buttons
	$: canIncrement = max === null || value + batch <= max;
	$: canDecrement = value - batch >= min;

	$: disabled = max !== null && max === min;

	// editable buffer string with the raw value as is typed by the user
	let buffer = value.toString();

	// The exported `value` is the validated version of `buffer`, continuously updated in real-time to stay within the minimum, maximum, and batch size limits.
	// This ensures that cost estimates and other calculations remain accurate as the user types, even if the input is temporarily invalid.

	// While the user input is invalid we show a red border around UnitInput field.
	// Once they leave the field, we update it to match the closest valid `value`.

	// calculate adjusted value, checking min, max, and batch_size
	function updateValue(val: number) {
		let adjusted = val;

		if (isNaN(adjusted)) adjusted = min;
		if (adjusted < min) adjusted = min;
		if (max && adjusted > max) adjusted = max;
		if (batch > 1 && adjusted % batch !== 0) adjusted = (Math.trunc(adjusted / batch) + 1) * batch;

		value = adjusted;
		dispatch('change', value);
		error = val !== value;
	}

	function updateBy(amount: number) {
		updateValue(value + amount);
		buffer = value.toString();
	}

	// update buffer to the adjusted value, and clear error
	function handleBlur() {
		buffer = value.toString();
		error = false;
	}

	function checkNumeric(e: InputEvent) {
		if (e.data && !/^\d*$/.test(e.data)) e.preventDefault();
	}
</script>

<div class:error class="w-28">
	<span
		class="border-r"
		class:disabled={!canDecrement}
		on:click={() => canDecrement && updateBy(-batch)}
		on:keydown
		role="button"
		tabindex="-1"
	>
		<svg xmlns="http://www.w3.org/2000/svg">
			<rect x="3" y="7.25" width="10" height="1.5" rx="0.75" />
		</svg>
	</span>

	<input
		{disabled}
		class:disabled
		bind:value={buffer}
		on:input={() => updateValue(parseInt(buffer))}
		on:blur={handleBlur}
		on:beforeinput={checkNumeric}
	/>

	<span
		class="border-l"
		class:disabled={!canIncrement}
		on:click={() => canIncrement && updateBy(batch)}
		on:keydown
		role="button"
		tabindex="-1"
	>
		<svg xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.5 2.75C8.5 2.33579 8.16421 2 7.75 2C7.33579 2 7 2.33579 7 2.75V7H2.75C2.33579 7 2 7.33579 2 7.75C2 8.16421 2.33579 8.5 2.75 8.5H7V12.75C7 13.1642 7.33579 13.5 7.75 13.5C8.16421 13.5 8.5 13.1642 8.5 12.75V8.5H12.75C13.1642 8.5 13.5 8.16421 13.5 7.75C13.5 7.33579 13.1642 7 12.75 7H8.5V2.75Z"
			/>
		</svg>
	</span>
</div>

<style lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	div {
		@apply grid grid-flow-col bg-white shadow-button border-control;
	}
	div:focus-within:not(.error) {
		@apply outline-control-active;
	}
	div:focus-within.error {
		@apply outline-control-active-error;
	}
	input {
		@apply w-12 py-2 px-2 text-center;
		@apply outline-none outline-offset-0;
	}
	div.error {
		@apply border-error;
	}
	div > span {
		@apply grid w-8 place-content-center border-zinc-200;
	}

	div > span > svg {
		@apply h-4 w-4 cursor-pointer fill-current;
	}
	div > span.disabled > svg {
		@apply cursor-default fill-zinc-300;
	}
	div > input.disabled {
		@apply text-zinc-500;
	}
</style>
