import type {
	AddMethodSuccess,
	Address,
	PaymentChangeEvent,
	PaymentSession,
	UpdateSessionStatus,
} from '$lib/types';
import { EMPTY_ADDRESS } from '$lib/types';

import { loadStripe } from '@stripe/stripe-js';
import type {
	Appearance,
	StripeElements,
	StripeElementsOptions,
} from '@stripe/stripe-js/dist/stripe-js/elements-group';
import type {
	SetupIntentResult,
	Stripe,
	StripeError,
} from '@stripe/stripe-js/dist/stripe-js/stripe';

//from '@stripe/stripe-js/types/stripe-js';
import { VERSION } from '$lib/constants';

import { getSession, readAddress, updateSessionStatus, upsertAddress } from '$lib/api/payment';
import { type Err, errFromException } from '$lib/utils/error';
import { createPaymentElement, toStripeAddress } from '$lib/utils/stripe';

import { PaymentForm } from '$lib/components';

let initialized = false;

// library state
let _debug = false;
let _token: string | undefined;
let _session: PaymentSession | undefined;
let _client_secret: string | undefined;

let _address: Address | undefined = undefined;

let _stripe: Stripe | undefined;
let _elements: StripeElements | undefined;
let _form: PaymentForm;

// init function: paymentElement constructor
export const init = async (token: string, { debug = false } = {}) => {
	clear();

	_debug = !!debug;
	if (_debug) console.info(`wingback-js: running ${VERSION}`);

	if (!token) throw new Error('no payment token session specified');
	_token = token;

	// GET /p/session auth:payment-session-token header
	_session = await getSession(_token);

	const auth = _session?.setup_intent?.stripe;
	if (!auth) throw new Error('invalid session. no stripe auth data found');

	_client_secret = auth.client_secret;
	const { pk_key, connected_account_id: stripeAccount } = auth;

	_stripe = (await loadStripe(pk_key, { stripeAccount })) || undefined;
	if (!_stripe) throw new Error('could not initialize stripe library');

	initialized = true;
	return paymentElement;
};

const clear = () => {
	initialized = false;
	_address = _session = _token = _stripe = _elements = undefined;
	if (_form) _form.$destroy();
};

type RenderOptions = {
	raw?: boolean;
	onchange?: (event: CustomEvent<PaymentChangeEvent>) => unknown;
	fonts?: StripeElementsOptions['fonts'];
	appearance?: Appearance;
	addressControls?: boolean; // let stripe display addressControls
};

const render = async (el: HTMLElement | string, options?: RenderOptions) => {
	if (!initialized || !_token || !_client_secret || !_stripe || !_session)
		throw new Error('wingback library not initialized');

	if (!el) throw new Error('must specify html element to mount wingback formElement');

	if (typeof el === 'string') {
		const tmpEl = document.getElementById(el) || document.querySelector(el);
		if (!tmpEl) throw new Error(`could not find HTMLElement by css selector ${el}`);
		el = tmpEl;
	}

	options = options || {};
	const { raw, fonts, appearance } = options;

	const withAddress = _session.address;

	// read current address from backend
	_address = withAddress ? { ...EMPTY_ADDRESS, ...((await readAddress(_token)) || {}) } : undefined;

	// create stripe elements and paymentElement
	const { elements, paymentElement } = createPaymentElement(_stripe, _client_secret, {
		withAddress,
		fonts,
		appearance,
	});
	_elements = elements;

	if (!_elements) throw new Error('could not create stripe elements');
	if (!paymentElement) throw new Error('could not create stripe payment element');

	el.replaceChildren(); // clear target
	_form = new PaymentForm({
		target: el,
		props: {
			raw,
			address: _address,
			element: paymentElement,
		},
	});
	if (options?.onchange) _form.$on('change', options.onchange);
	// listen to changes on address
	if (withAddress)
		_form.$on('changeAddress', ({ detail }: { detail: Address }) => (_address = detail));

	// wait for PaymentElement to be ready to return
	await new Promise((resolve) => _form.$on('ready', () => resolve(null)));

	return withAddress ? _address : undefined;
};

const validate = (address?: Address): Err | null => {
	if (!_form) return null;
	if (address) _form.$set({ address });
	return _form.validate();
};

type ConfirmOptions = {
	success?: (result: AddMethodSuccess) => void;
	error?: (result: StripeError | Err) => void;
	address?: Address; // also includes holder_name
};

const confirm = async (options: ConfirmOptions) => {
	const handleError = <E extends Err | StripeError | string>(
		error: E,
		debugTitle = 'Error on wingback.confirmForm'
	) => {
		const e: Err | StripeError = typeof error === 'string' ? errFromException(error) : error;
		_debug && console.error(debugTitle, { error: e });
		// capture auth error "invalid or expired payment session token"
		if ('fields' in e && e.fields.auth) e.message = e.fields.auth;
		if (options?.error) options.error(e); // call error callback
		else throw error;
		return;
	};

	// check all variables so typescript won't complain
	if (!initialized || !_token || !_session || !_stripe)
		return handleError('wingback library not initialized');

	if (!_elements) return handleError('stripe elements not initialized');

	// options.success callback is mandatory
	if (typeof options?.success !== 'function') return handleError('success callback not specified');

	// update the address of the component before applying validations
	if (options.address) _form.$set({ address: options.address });

	const error = _form.validate();
	if (error) return handleError(error, 'Error validating payment form');

	let setupIntentResult: SetupIntentResult;

	// save address information to backend
	if (_session.address) {
		// override EMPTY_ADDRESS, with _address and options.address
		const address = { ...EMPTY_ADDRESS, ...(_address || {}), ...(options.address || {}) };

		try {
			await upsertAddress(_token, address);
		} catch (error) {
			return handleError(error as Err, 'Error updating address');
		}

		try {
			setupIntentResult = await _stripe.confirmSetup({
				elements: _elements,
				redirect: 'if_required',
				confirmParams: {
					payment_method_data: {
						billing_details: {
							name: address.holder_name || '',
							address: toStripeAddress(address),
						},
					},
				},
			});
			_address = address;
		} catch (error) {
			return handleError(error as Err, 'Error confirming stripe setup with address');
		}
	} else {
		try {
			setupIntentResult = await _stripe.confirmSetup({
				elements: _elements,
				redirect: 'if_required',
			});
		} catch (error) {
			return handleError(error as Err, 'Error confirming stripe setup with no address');
		}
	}

	// handle result
	// call backend /p/complete and
	// call user callbacks if defined
	const succeeded = setupIntentResult?.setupIntent?.status === 'succeeded';

	// handle error
	if (!succeeded) {
		// _debug && console.error('Error confirming stripe setup', { error: setupIntentResult.error });
		return handleError(setupIntentResult.error as StripeError, 'Error confirming stripe setup');
	}

	try {
		const updateStatus: UpdateSessionStatus = {
			status: 'complete',
			holder_name: options?.address?.holder_name || _address?.holder_name || '',
		};
		const success = await updateSessionStatus(_token, updateStatus); // save new payment method in backend
		if (options?.success) options.success(success); // call callback
	} catch (error) {
		return handleError(error as Err, 'Error completing payment');
	}

	return setupIntentResult;
};

const paymentElement = {
	init,
	render,
	validate,
	confirm,
};

export type PaymentElement = typeof paymentElement;
