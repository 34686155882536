export function unique<T>(items: T[]) {
	return [...new Set(items)];
}

export function isEqual(obj1: unknown, obj2: unknown) {
	if (typeof obj1 !== typeof obj2) return false;

	// object, array, null
	if (typeof obj1 === 'object' && typeof obj2 === 'object') {
		// deal with null
		if (obj1 === null || obj2 === null) return obj1 === obj2;

		const keys1 = Object.keys(obj1) as [keyof typeof obj1];

		if (keys1.length !== Object.keys(obj2).length) return false;

		for (const key of keys1) {
			if (!(key in obj2)) return false;
			if (!isEqual(obj1[key], obj2[key])) return false; // recursive
		}
		return true;
	}

	// rest of the types
	return obj1 === obj2;
}
