<script lang="ts">
	import type { Address as AddressType, PaymentChangeEvent } from '$lib/types';

	import { onMount } from 'svelte';
	import { createEventDispatcher } from 'svelte';

	import type { StripePaymentElement } from '@stripe/stripe-js';

	import type { Err } from '$lib/utils/error';

	import Address from './Address.svelte';

	export let address: AddressType | undefined;
	export let element: StripePaymentElement;
	/** Display the raw Payment element as returned by stripe, with no wrapper or title */
	export let raw = false;
	export let isEmpty = false;
	export let isComplete = false;

	const dispatch = createEventDispatcher<{
		change: PaymentChangeEvent;
		changeAddress: AddressType;
		ready: null;
	}>();

	let el: HTMLDivElement; // html element where the Payment element will be mounted

	let paymentAddress: Address; // PaymentAddress bound instance to call paymentAddress.validate()

	element.on('change', (event) => {
		const { complete, empty } = event;
		isComplete = complete;
		isEmpty = empty;
		dispatch('change', { complete, empty });
	});

	element.on('ready', (_event) => dispatch('ready'));

	onMount(() => element.mount(el)); // mount stripe element when el is ready

	type FormFields = AddressType & {
		paymentElement: string;
	};

	export const validate = () => {
		let fields: Partial<FormFields> = {};

		if (isEmpty) {
			fields.paymentElement =
				'Payment method information is empty. Enter card number, expiry and cvc.';
		} else if (!isComplete) {
			fields.paymentElement =
				'Payment method information is invalid. Check card number, expiry and cvc.';
		}

		// only validate address fields if we are displaying the address component
		if (address && paymentAddress) {
			// update field with errors if we are displaying address form
			const error = paymentAddress.validate();
			if (error) fields = { ...fields, ...error.fields };
		}

		// no error
		if (Object.keys(fields).length === 0) return null;

		return {
			message: 'Some fields have invalid values',
			isValid: false,
			fields,
		} as Err;
	};

	// notify address updates to wingback.js
	$: if (address) {
		dispatch('changeAddress', address);
	}
</script>

<svelte:head>
	<link
		href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
		rel="stylesheet"
	/>
</svelte:head>

{#if raw}
	<div bind:this={el} />
{:else}
	<!-- PaymentForm container -->
	<div class="wb-payment grid grid-flow-row gap-12 md:grid-flow-col">
		<div class="wb-payment-element-container grid grid-flow-row gap-4 md:self-start">
			<p class="wb-payment-element-label font-sans text-h200 font-bold">Card information</p>

			<!-- An iframe with the Stripe Payment Element will be inserted inside the div. -->
			<div class="wb-payment-element" bind:this={el} />
		</div>
		{#if address}
			<Address bind:address bind:this={paymentAddress} />
		{/if}
	</div>
{/if}

<style lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;
</style>
