import type { Address } from '$lib/types';

import type {
	Appearance,
	PaymentMethodCreateParams,
	Stripe,
	StripeElementsOptions,
	StripePaymentElementOptions,
} from '@stripe/stripe-js';

export function toStripeAddress(
	address: Partial<Address>
): PaymentMethodCreateParams.BillingDetails.Address {
	return {
		line1: address.line_1 || '',
		line2: address.line_2 || '',
		city: address.city || '',
		postal_code: address.zip || '',
		state: address.state || '',
		country: address.country || '',
	};
}

type CreatePaymentElementOptions = {
	withAddress?: boolean;
	fonts?: StripeElementsOptions['fonts'];
	appearance?: Appearance;
};

export function createPaymentElement(
	stripe: Stripe,
	clientSecret: string,
	{ withAddress = false, fonts, appearance }: CreatePaymentElementOptions
) {
	// check all variables so typescript won't complain
	if (!stripe) throw new Error('wingback library not initialized');

	// values extracted from tailwind.config.cjs
	const fontSize_h200 = '0.875rem';
	const lineHeight_h200 = '20.3px';

	const white = '#FFFFFF';
	const red_900 = '#7f1d1d';
	const zinc_300 = '#d4d4d8';
	const zinc_500 = '#71717a';
	const zinc_600 = '#52525b';
	const zinc_900 = '#18181b';

	const outline_none = '2px solid transparent';
	const outlineOffset_none = '2px';

	const rounded_lg = '8px';

	const shadow_active_input = '0px 0px 0px 2px #C4D8FD, inset 0px 0px 8px rgba(0, 0, 0, 0.04)';
	const shadow_active_danger = '0px 0px 0px 2px #FCA5A5';
	const border_invalid = '1px solid #EF4444';

	// fonts default value
	fonts = fonts || [
		{ cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap' },
	];

	// appearance default value
	appearance = appearance || {
		// see: https://stripe.com/docs/stripe-js/appearance-api#variables
		variables: {
			colorIconCardError: red_900,
			colorIconCardCvcError: red_900,
			spacingGridColumn: '8px',
			spacingGridRow: '16px',
			fontSizeBase: '16px',
			fontFamily:
				'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
		},
		// see: https://stripe.com/docs/stripe-js/appearance-api#rules
		rules: {
			'.Label': {
				fontSize: fontSize_h200,
				lineHeight: lineHeight_h200,
				marginBottom: '8px',
				color: zinc_600,
			},
			'.Input': {
				fontSize: fontSize_h200,
				lineHeight: lineHeight_h200,
				outline: outline_none, // tailwind outline-none
				outlineOffset: outlineOffset_none, // tailwind outline-none
				borderRadius: rounded_lg,
			},
			'.Input::placeholder': {
				color: zinc_500,
			},
			'.Input:focus': {
				boxShadow: shadow_active_input,
				borderColor: zinc_300,
			},
			'.Input--invalid': {
				border: border_invalid,
				color: zinc_900,
				boxShadow: 'none',
			},
			'.Input--invalid:focus': {
				boxShadow: shadow_active_danger,
				borderColor: white,
			},
			'.Error': {
				fontSize: fontSize_h200,
				lineHeight: lineHeight_h200,
				color: red_900,
				marginTop: '9px',
			},
		},
	};

	const elementsOptions: StripeElementsOptions = { clientSecret, fonts, appearance };

	// create element, paymentElement requires the clientSecret to be passed to stripe.elements
	// see: https://stripe.com/docs/payments/payment-element/migration#one-time-update-elements
	// elementsOptions: see https://stripe.com/docs/js/elements_object/create
	const elements = stripe.elements(elementsOptions);
	if (!elements) throw new Error('could not create stripe elements instance');

	const paymentElementOptions: StripePaymentElementOptions = {
		// suppress the 'By providing your card information...' message below the credit card and SEPA debit info
		// see https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options-terms
		terms: { card: 'never', sepaDebit: 'never' },
		// see https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options-wallets
		wallets: { applePay: 'never', googlePay: 'never' },
	};

	// iw withAddress is true, we will take care of the country combo
	if (withAddress) {
		paymentElementOptions.fields = {
			billingDetails: { address: 'never' },
		};
	}

	const paymentElement = elements.create('payment', paymentElementOptions);
	if (!paymentElement) throw new Error('could not create stripe payment element');

	return { elements, paymentElement };
}
