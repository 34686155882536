<script lang="ts">
	import { type Address, EMPTY_ADDRESS } from '$lib/types';

	import { validateAddress, validateAddressFields } from '$lib/utils/address';
	import type { Err } from '$lib/utils/error';

	import Input from '$lib/components/payment/Input.svelte';

	export let address: Address = EMPTY_ADDRESS;

	let error: Address = { ...EMPTY_ADDRESS };

	export const validate = (): Err | null => {
		validateField('all'); // update error object
		return validateAddress(address);
	};

	const validateField = (field: keyof Address | 'all') =>
		(error = validateAddressFields(address, error, field));

	// country should always be in upper case
	$: address.country = address.country.toUpperCase();
</script>

<div class="wb-address grid grid-flow-row gap-4">
	<p class="wb-address-label font-sans text-h200 font-bold">Billing information</p>

	<Input
		class="wb-address-name"
		title="Cardholder name"
		id="name"
		placeholder=""
		error={error.holder_name}
		bind:value={address.holder_name}
		on:input={() => validateField('holder_name')}
	/>

	<!-- address line 1 & 2 -->
	<div class="wb-address-line-container grid grid-flow-row gap-2">
		<Input
			class="wb-address-line_1"
			title="Address line"
			id="line_1"
			placeholder="Address line 1"
			error={error.line_1}
			bind:value={address.line_1}
			on:input={() => validateField('line_1')}
		/>
		<Input
			class="wb-address-line_2"
			id="line_2"
			placeholder="Address line 2"
			error={error.line_2}
			bind:value={address.line_2}
			on:input={() => validateField('line_2')}
		/>
	</div>

	<div class="grid grid-cols-2 gap-2">
		<Input
			class="wb-address-city"
			title="City"
			id="city"
			placeholder=""
			error={error.city}
			bind:value={address.city}
			on:input={() => validateField('city')}
		/>

		<Input
			class="wb-address-zip"
			title="Zip"
			id="zip"
			placeholder=""
			error={error.zip}
			bind:value={address.zip}
			on:input={() => validateField('zip')}
		/>
	</div>

	<div class="tw-bug grid grid-cols-2 gap-2">
		<Input
			class="wb-address-state"
			title="State"
			id="state"
			placeholder=""
			error={error.state}
			bind:value={address.state}
			on:input={() => validateField('state')}
		/>

		<Input
			class="wb-address-country"
			title="Country"
			id="country"
			placeholder=""
			error={error.country}
			bind:value={address.country}
			on:input={() => (validateField('country'), validateField('state'))}
		/>
	</div>
</div>

<style lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	/* do not remove this: tailwind bug, it won't be applied if this is missing */
	div.tw-bug {
		@apply grid grid-cols-2 gap-2;
	}
</style>
