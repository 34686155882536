export const ERR_MESSAGE_INVALID_FIELDS = 'Some fields have invalid values';
export const ERR_MESSAGE_DUPLICATE_VALUE = 'Duplicate value detected';

type Feedback = {
	[key: string]: {
		type: string;
		value: string;
	};
};

export type Err = {
	message?: string;
	isValid: boolean;
	fields: {
		[key: string]: string; // only keys with type 'Error' associated to a field
	};
	feedback?: Feedback;
	response?: {
		status: number;
		text: string;
	};
};

// helper functions to generate error object
export function errFromException(ex: unknown): Err {
	const message =
		ex instanceof Error
			? ex.message
			: typeof ex === 'string'
			? ex
			: 'error accessing wingback backend';
	return { message, isValid: false, fields: {} };
}

export async function errFromResponse(res: Response) {
	const err: Err = {
		message: 'error accessing wingback backend',
		isValid: false,
		fields: {},
		response: {
			status: res.status,
			text: res.statusText,
		},
	};

	// try to parse and process feedback
	const feedback = await parseFeedback(res);
	if (feedback) {
		err.feedback = feedback;
		for (const key in feedback) {
			if (feedback[key].type !== 'error') continue; // only process errors

			const duplicateField =
				key === 'constraint-violation' ? parseDuplicateField(feedback[key].value) : null;
			if (duplicateField) {
				err.message = ERR_MESSAGE_DUPLICATE_VALUE;
				err.fields[duplicateField] = `${duplicateField} is duplicated`;
			} else if (
				key === 'internal-error' ||
				key === 'constraint-violation' ||
				key === 'not-found'
			) {
				err.message = `${feedback[key].value} (${key})`;
			} else {
				// it's a field
				err.message = ERR_MESSAGE_INVALID_FIELDS;
				err.fields[key] = feedback[key].value;
			}
		}
	}
	return err;
}

// example: tag_name_already_provided
function parseDuplicateField(message: string) {
	const regExp = /^(.+?)_(.+?)_already_provided$/;
	const result = regExp.exec(message); // Array(3) [ "tag_name_already_provided", "tag", "name" ]
	return result ? result[2] : null;
}

// try to read feedback object from response body
async function parseFeedback(response: Response) {
	try {
		const json = await response.json();
		return json.feedback ? (json.feedback as Feedback) : undefined;
	} catch {
		return undefined;
	}
}
