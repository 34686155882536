<script lang="ts">
	import { slide } from 'svelte/transition';

	import { duration } from '$lib/constants';

	export let error = '';
</script>

{#if error}
	<div class="wb-error-container" transition:slide|global={{ duration }}>
		<span class="wb-error">{error}</span>
	</div>
{/if}

<style lang="postcss">
	div {
		@apply grid grid-flow-col justify-start gap-1;
		@apply text-h200 font-normal text-red-900;
	}
</style>
